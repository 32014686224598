import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SimpleResponse } from "./admin.service";

export type OrderAddress = {};

export type Price = {
  CZK: number;
  EUR: number;
};

export type Order = {
  id: string;
  created_at: Date;
  currency: string;
  price: number;
  priceWithVat: number;
  vat: number;
  vs: string;
  paymentStatus: string;
  items: {
    type: string;
    name: string;
    price: number;
  }[];
  color?: string;
  icon?: string;
  accountingDocumentID?: number;
};

export type Item = {
  name: string;
  type: string;
  price: Price;
};

export type ItemCreateOrder = {
  name: string;
  type: string;
  price: number;
};

export type ObjectItem = {
  [key: string]: Item;
};

export type BillableItem = {
  accountID: string;
  amount: number;
  currency: string;
  billedAt: Date;
};

export type BillableRow = {
  date: string;
  topup: boolean;
  sumCredits: number;
};

export type CreateOrderResponse = {
  message: string;
  status: string;
  redirectUrl?: string;
};

export type GetOrderResponse = {
  message: string;
  status: string;
  order?: Order;
};

export type FindOrdersResponse = {
  message: string;
  status: string;
  orders?: Order[];
};

export type GetAccountStateResponse = {
  message: string;
  status: string;
  availableBudget?: number;
  budgetExpiration?: Date;
  availableStorage?: number;
  storageExpiration?: Date;
};

export type FindAccountUsageResponse = {
  message: string;
  status: string;
  billableRows?: BillableRow[];
};

type ContactForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  message: string;
};

@Injectable({
  providedIn: "root",
})
export class EshopService {
  constructor(private http: HttpClient) {}

  createOrder(
    items: ItemCreateOrder[],
    currency: string,
    address: OrderAddress,
    region: string,
    language: string
  ) {
    return this.http.post<CreateOrderResponse>(
      `${environment.url}/create-order`,
      {
        items: items,
        currency: currency,
        address: address,
        region: region,
        language: language,
      }
    );
  }

  getOrder(vs: string) {
    return this.http.post<GetOrderResponse>(`${environment.url}/get-order`, {
      vs: vs,
    });
  }

  findOrders() {
    return this.http.get<FindOrdersResponse>(`${environment.url}/find-orders`);
  }

  getAccountState() {
    return this.http.get<GetAccountStateResponse>(
      `${environment.url}/get-account-state`
    );
  }

  findAccountUsage() {
    return this.http.get<FindAccountUsageResponse>(
      `${environment.url}/find-account-usage`
    );
  }

  downloadAccountingDocument(vs: string) {
    return this.http.post(
      `${environment.url}/download-accounting-document`,
      {
        vs: vs,
      },
      {
        responseType: "blob",
      }
    );
  }

  contactSupport(formValues: ContactForm, type: string) {
    return this.http.post(`${environment.url}/contact-support`, {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
      companyName: formValues.companyName,
      message: formValues.message,
      type: type,
    });
  }

  validateVat(vatNumber: string) {
    return this.http.post<SimpleResponse>(`${environment.url}/validate-vat`, {
      vatNumber: vatNumber,
    });
  }
}
